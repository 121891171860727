import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import LogoModal from "../../assets/ic-LogoModal.png";
import { ReactComponent as Coupon } from "../../assets/ic-coupon.svg";
import Modal from "./ModalsRsvp.style";

//import { RiCoupon2Line } from 'react-icons/ri';

import { createBooking } from "services/http";
import { clickOnConfirmReservation } from "shared/facebook";
import { modalMessages } from "./contants";

const isForSportCenter = !!process.env.REACT_APP_CENTER_KEY;
function highlightText(text, highlight, className) {
  const parts = text.split(new RegExp(`(${highlight})`, "gi")); // Divide el texto en partes
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={index} className={className}>
        {part}
      </span>
    ) : (
      part  
    )
  );
}

function formatCOP(value) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value).replace('COP', '$');
}

const ModalsRsvp = (props) => {
  const withOutCreadit =
    !!props?.reservationWitOuthCreditMemo && !props?.reservationWithCreditMemo;
  const withCreadit =
    !!props?.reservationWithCreditMemo && !props?.reservationWitOuthCreditMemo;
  const bothCreadit =
    !!props?.reservationWithCreditMemo && !!props?.reservationWitOuthCreditMemo;
  const [creaded, setCreaded] = useState(false);
  const {
    modalRsvpCallBack,
    showModal,
    fieldData,
    rsvpInfo,
    modalcenter,
    selectedDate,
    cuponInfo,
    calculateDiscount,
    resquestInfoCoupon,
  } = props;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingWithOut, setLoadingWithOut] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageUnavailable, setMessageUnavailable] = useState(false);
  const [messageNoMatch, setMessageNoMatch] = useState(false);
  const [infoRsvp, setInfoRsvp] = useState({});
  const [dataField, setDataField] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  // console.log(!!props?.reservationWitOuthCreditMemo, props?.reservationWithCreditMemo)
  const [messageErrorCupon, setMessageErrorCupon] = useState(false);
  const [loadingCupon, setLoadingCupon] = useState(false);
  const [cupon, setCupon] = useState({});

  const [form] = Form.useForm();

  const date = new Date(infoRsvp.timestamprsvp);
  const timeRsvp = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const dateEnd = new Date(infoRsvp.timestamprsvpend);
  const timeRsvpEnd = dateEnd.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const reservar = async (payload) => {
    try {
      setMessageErrorCupon(false);
      if (!!payload?.withOutCreadit) {
        setLoadingWithOut(true);
      } else {
        setLoading(true);
      }
      setDisabledButton(true);
      const rsvpDate = moment(selectedDate);
      const rsvpStart = moment(infoRsvp.timestamprsvp).set({
        year: rsvpDate.get("year"),
        month: rsvpDate.get("month"),
        date: rsvpDate.get("date"),
      }); //.date(rsvpDate.date()).year(rsvpDate.year());
      const now = moment();
      let rsvpEnd = moment(infoRsvp.timestamprsvpend).set({
        year: rsvpDate.get("year"),
        month: rsvpDate.get("month"),
        date: rsvpDate.get("date"),
      });
      if (rsvpEnd.hour() === 0) {
        rsvpEnd = moment(infoRsvp.timestamprsvpend).set({
          year: rsvpDate.get("year"),
          month: rsvpDate.get("month"),
          date: rsvpDate.get("date"),
          hour: 23,
          minute: 59,
        });
      }

      const dataRsvp = {
        timestamprsvp: rsvpStart.unix(),
        timestamprsvpend: rsvpEnd.unix(),
        tarifa: infoRsvp.monto,
        idsede: dataField.idsede,
        idcentro: dataField.idcentro,
        idcancha: dataField.idcancha,
        cupon: cupon.name,
        ...(!!payload?.withOutCreadit && { reservasinpago: true }),
        ...(!payload?.withOutCreadit && { reservaconpago: true }),
      };
      // console.log({ isForSportCenter, dataField });
      //console.log({ rsvpDate, rsvpStart, now, rsvpEnd, dataRsvp, unix: now.unix() });
      if (dataRsvp.timestamprsvp < now.unix()) {
        notification.error({
          message: "No es posible realizar esta reserva",
          description:
            "Hay incoherencia con las fechas suministradas, por favor intenta con otra fecha, si el problema persiste por favor contacta a soporte en info@icancha.com",
          duration: 3,
        });
        //console.log({ dataRsvp, unix: now.unix() });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return;
      }
      const request = await createBooking(
        dataRsvp.timestamprsvp,
        dataRsvp.timestamprsvpend,
        dataRsvp.tarifa,
        dataRsvp.idsede,
        dataRsvp.idcentro,
        dataRsvp.idcancha,
        dataRsvp.cupon,
        dataRsvp.reservasinpago,
        dataRsvp.reservaconpago
      );
      if (request.cod === 1000) {
        const { data: response } = request;
        // El servidor respondió correctamente
        // Se verifica si se logro la reserva
        if (response.reservada) {
          // La reserva no se realizó ya que la hora estaba reservada
          // Colocar un mensaje
          setMessageUnavailable(true);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
        if (response.noMatch) {
          // La reserva no se realizó ya que hay una anomalía en los parametros
          setMessageNoMatch(true);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
        if (response.idhistoricorsvp) {
          if (!isForSportCenter) {
            clickOnConfirmReservation(
              dataField.country,
              dataField.iddeporte,
              response.idhistoricorsvp,
              dataField.idcentro,
              dataField.idsede,
              dataField.idcancha,
              dataRsvp.tarifa,
              dataRsvp.timestamprsvp,
              dataRsvp.timestamprsvpend
            );
          }
          setLoading(false);
          setLoadingWithOut(false);
          setMessage(true);
          setDisabledButton(false);
          const { urlPago } = request.data;
          if (payload?.withOutCreadit) {
            setCreaded(true);
          }
          if (!payload?.withOutCreadit) {
            setTimeout(() => {
              window.location.assign(`${urlPago}`);
            }, 2000);
          }
        }
      } else {
        setLoading(false);
        setLoadingWithOut(false);
        setDisabledButton(false);
        throw new Error(`${request.cod}`);
      }
    } catch (error) {
      setLoading(false);
      setLoadingWithOut(false);
      console.log("Error reserva", error.message);
      if (error.message === "1012") {
        setCupon({});
        setMessageErrorCupon(true);
        setLoadingCupon(false);
      } else {
        notification.warning({
          message: "No podemos hacer contacto con el servidor iCancha",
          description:
            "Ocurrió un error al intentar conectarse al servidor, si el problema persiste por favor contacta a soporte en info@icancha.com",
          duration: 3,
        });
      }
    }
  };

  const handleCancel = () => {
    setCreaded(false);
    setMessage(false);
    form.resetFields();
    //console.log(cuponInfo);
    setMessageErrorCupon(false);
    if (cupon) {
      setCupon({});
    }
    modalRsvpCallBack(false);
    setVisible(false);
    if (creaded) {
      window.location.reload();
    }
  };

  const validarCupon = async (values) => {
    setMessageErrorCupon(false);
    setLoadingCupon(true);
    //console.log({ values });
    const request = await resquestInfoCoupon(values.codeCoupon);
    //console.log({ request });

    if (request && request.valid) {
      setCupon({ ...request, value: parseFloat(request.value) });
      setMessageErrorCupon(false);
      setLoadingCupon(false);
    } else {
      setCupon({});
      setMessageErrorCupon(true);
      setLoadingCupon(false);
    }

    /*if (values.codeCupon === "123456") {
      setTimeout(() => {
        setCupon({
          valid: true,
          value: 10,
          percent: true,
          name: values.codeCupon,
        })
        setLoadingCupon(false);
      }, 5000);
    }
    else {
      setTimeout(() => {
        setMessageErrorCupon(true);
        setLoadingCupon(false);
      }, 4000)

    }*/
  };

  const onFinishFailed = (errorInfo) => {
    //console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (showModal) {
      setVisible(showModal);
      if (cuponInfo) {
        setCupon(cuponInfo);
      }
    }
  }, [showModal]);

  useEffect(() => {
    //console.log({ fieldData });
    if (fieldData) {
      setDataField(fieldData);
    }
  }, [fieldData]);

  useEffect(() => {
    //console.log({ rsvpInfo });
    if (rsvpInfo) {
      setInfoRsvp(rsvpInfo);
    }
  }, [rsvpInfo]);

  return (
    <>
      <Modal
        centered
        visible={visible}
        closable={false}
        footer={null}
        maskStyle={{ background: "rgba(4, 0, 47, 0.86)" }}
        zIndex={1040}
        onCancel={() => handleCancel()}
        width={438}
      >
        <Button
          variant="text"
          type="primary"
          onClick={() => handleCancel()}
          disabled={disabledButton}
          className="closeButton"
        >
          <CloseCircleOutlined className="close" />
        </Button>
        <Row className="Row-logoContent">
          {!modalcenter && (
            <Col>
              <div>
                <img src={LogoModal} alt="logo" />
              </div>
            </Col>
          )}
        </Row>
        <Form
          form={form}
          className="form-web-modal"
          name="formValidCupon"
          onFinish={validarCupon}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Row className="Row-ContentInfo--Rsvp">
            <Col span={24}>
              <h1>Resumen de tu Reserva</h1>
              {/*<h1>
              {`Estas por reservar en ${dataField.nombrecancha} en ${dataField.nombresede} a las ${timeRsvp} por un valor de $ ${infoRsvp.monto ? infoRsvp.monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ' '
                }`}
            </h1>
            <p>Una vez realizada la reserva tendrás 15 min para realizar el pago, en caso contrario perderás tu reserva y deberás iniciar el proceso nuevamente</p>
            {message && <p className='messageSuccess'>{`Has Pre-reservado en cancha a las ${timeRsvp} exitosamente, a continuación serás redireccionado a pagar la reserva`}</p>}
            {messageUnavailable && <p className='messageFailed'>{`No hemos podido reservar en esta hora debido a que ya fue reservada`}</p>}
            {messageNoMatch && <p className='messageFailed'>{`No hemos podido reservar en esta hora debido a un problema en el centro por favor contacta a info@icancha.com`}</p>}
              */}
            </Col>
            <Col span={7} className="Col-ContentInfo--left border">
              <p className="subtitle">Concepto</p>
            </Col>
            <Col span={17} className="Col-ContentInfo--right border">
              <p className="info">{`Reserva en ${dataField.nombrecancha} en ${dataField.nombresede} desde ${timeRsvp} hasta ${timeRsvpEnd}`}</p>
            </Col>
            <Col span={10} className="Col-ContentInfo--left">
              <p className="subtitle">Valor a Pagar</p>
            </Col>
            <Col span={14} className="Col-ContentInfo--right">
              {!cupon.valid ? (
                <p className="monto">{formatCOP(infoRsvp.monto)}</p>
              ) : (
                <p className="monto">
                  {formatCOP(
                    calculateDiscount(
                      infoRsvp.monto,
                      cupon.value,
                      cupon.percent
                    )
                  )}
                </p>
              )}
            </Col>
          </Row>
          {!creaded && (
            <Row className="Row-ContentCupon">
              {!cupon.valid ? (
                <>
                  <Col span={24} className="Col-text">
                    <h1>Cupón de Descuento</h1>
                  </Col>
                  <Col span={24} className="Col-text">
                    <p>
                      Si tienes un código de descuento, ingrésalo a
                      continuación, redímelo y se feliz!
                    </p>
                  </Col>
                  {/*<Col span={20} className='Col-ContentInput'>
                      <Form.Item name='codeCoupon' rules={[{ min: 4, message: '¡Por favor codigo debe ser mayor a 4 digitos!' }]}>
                        <Input placeholder='N° Código' />
                      </Form.Item>
                    </Col>
                    <Col span={4} className='Col-iconCupon'>
                      <Button htmlType='submit' loading={loadingCupon}>
                        {!loadingCupon && <RiCoupon2Line />}
                      </Button>
                    </Col>*/}
                  <Col span={20} className="Col-ContentInput">
                    <Form.Item
                      name="codeCoupon"
                      rules={[
                        {
                          min: 4,
                          message:
                            "¡Por favor codigo debe ser mayor a 4 digitos!",
                        },
                      ]}
                    >
                      <Input placeholder="N° Código" />
                    </Form.Item>
                  </Col>
                  <Col span={4} className="Col-iconCupon">
                    <Button htmlType="submit" loading={loadingCupon}>
                      {!loadingCupon && <Coupon />}
                    </Button>
                  </Col>
                  <Col span={24} className="messageInfo">
                    {messageErrorCupon && (
                      <p>El cupón no es válido, por favor intente de nuevo</p>
                    )}
                  </Col>
                  <Col span={8} className="Col-ContentInfo--left">
                    <p className="subtitle">Total a Pagar</p>
                  </Col>
                  <Col span={16} className="Col-ContentInfo--right">
                    <p className="finishAmount">
                      {formatCOP(infoRsvp?.monto ?? "")}
                    </p>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={8} className="Col-ContentInfo--left border">
                    <p className="subtitle">Subtotal</p>
                  </Col>
                  <Col span={16} className="Col-ContentInfo--right border">
                    <p className="info-amount">
                      {formatCOP(infoRsvp?.monto) ?? ""}
                    </p>
                  </Col>
                  <Col span={8} className="Col-ContentInfo--left border">
                    <p className="subtitle">Descuento</p>
                  </Col>
                  <Col span={16} className="Col-ContentInfo--right border">
                    <p className="info-amount">{`$ ${
                      cupon?.percent
                        ? (
                            (parseFloat(infoRsvp?.monto) *
                              parseFloat(cupon?.value)) /
                            100
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : cupon?.value
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }`}</p>
                  </Col>
                  <Col span={8} className="Col-ContentInfo--left">
                    <p className="subtitle">Total a Pagar</p>
                  </Col>
                  <Col span={16} className="Col-ContentInfo--right">
                    <p className="finishAmount color-finish">
                      {formatCOP(
                        calculateDiscount(
                          infoRsvp?.monto,
                          cupon?.value,
                          cupon?.percent
                        )
                      )}
                    </p>
                  </Col>
                </>
              )}
            </Row>
          )}
          {!!creaded && (
            <Row className="Row-button">
              <Col span={24} className="Col-content--message">
                <p className="successCreate">¡Reserva confirmada!</p>
                <p className="description">
                  De ser necesario el Centro Deportivo se comunicará contigo
                </p>
              </Col>
            </Row>
          )}
          {!message && (
            <Row className="Row-button remove-bottom-margin">
              <Col className="Col-content--message">
                <p className="messageInfo">
                  {bothCreadit &&
                    highlightText(
                      modalMessages.both,
                      "15 minutos",
                      "highlight"
                    )}
                  {!!withCreadit &&
                    highlightText(
                      modalMessages.reservationWithCreditMemo,
                      "15 minutos",
                      "highlight"
                    )}
                  {!!withOutCreadit &&
                    highlightText(
                      modalMessages.reservationWithoutCreditMemo,
                      "15 minutos",
                      "highlight"
                    )}
                </p>
                {message && (
                  <p className="messageSuccess">{`Has Pre-reservado en cancha desde ${timeRsvp} hasta ${timeRsvpEnd} exitosamente, a continuación serás redireccionado a pagar la reserva`}</p>
                )}
                {messageUnavailable && (
                  <p className="messageFailed">{`No hemos podido reservar en esta hora debido a que ya fue reservada`}</p>
                )}
                {messageNoMatch && (
                  <p className="messageFailed">{`No hemos podido reservar en esta hora debido a un problema en el centro por favor contacta a info@icancha.com`}</p>
                )}
              </Col>
              {(withOutCreadit || bothCreadit) && (
                <Col span={bothCreadit ? 12 : 24} className="Col-buttonLeft">
                  <Button
                    type="primary"
                    onClick={() => reservar({ withOutCreadit: true })}
                    loading={loadingWithOut}
                  >
                    Reserva sin abono
                  </Button>
                </Col>
              )}
              {(withCreadit || bothCreadit) && (
                <Col
                  span={bothCreadit ? 12 : 24}
                  className={`${
                    bothCreadit ? "Col-buttonRight" : "Col-buttonRight-2"
                  }`}
                >
                  <Button
                    type="primary"
                    onClick={() => reservar({})}
                    loading={loading}
                  >
                    Reserva con abono
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ModalsRsvp;
