import styled from "styled-components";

const ComponentTimeGrid = styled.div`
  .Row-title-dataTime {
    justify-content: space-between;

    .Row-header-dataTime {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;

      p {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .Col-content-dataTime {
      display: flex;
      flex-direction: column;
    }

    .Col-rateTable-dataTime {
      /* background-color: #f3f6f9; */
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 12px 4px 0px;
      border-radius: 8px;
      flex-grow: 1;
      padding: 0px 0px 2px;
      height: 256px;
    }

    .Col-rateTable-dataTime-availability {
      background-color: #f4f6f7;
      height: 262px;
      cursor: no-drop;
      box-shadow: 0px 2px 1px #e0e3e5;
    }

    .text-rateTable-dataTime {
      margin: auto;
      text-align: center;
      text-wrap: wrap;
      text-overflow: hidden;
      padding: 0px 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      color: #9daab8;
    }
  }

  .Row-Content--dataTime {
    padding: 11px 38px 20px;
  }

  .Row-grid {
    justify-content: space-between;
  }

  .hour-slot {
    width: 157px;
    /* height: 38px; */
    border-radius: 10px;
    /* border: 1px solid #a5abb0; */
    /* background-color: #fbfbfb; */
    color: #a5abb0;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #fbfbfb;
    box-shadow: 0px 2px 1px #e0e3e5;
    cursor: pointer;
    font-size: 12px;
    height: 100%;
  }

  .hour-slot-big {
    /* height: 52px; */
  }

  .hour-slot > button {
    font-weight: bold;
    border: 0px;
    margin: 0px;
    padding: 0px;
    height: 100%;
    border-radius: 10px;
    .hour-slot-text-dash {
      padding: 0px 2px;
      display: inline-block;
    }
  }

  .hour-available {
    box-shadow: 0px 2px 1px #e0e3e5;
  }

  .hour-morning {
    color: #ff9e44;
  }

  .hour-morning > button {
    color: #ff9e44;
  }

  .hour-afternoon {
    color: #ff7425;
  }

  .hour-afternoon > button {
    color: #ff7425;
  }

  .hour-night {
    color: #8a2be2;
  }

  .hour-night > button {
    color: #8a2be2;
  }

  .hour-early-morning {
    color: #c382d9;
  }

  .hour-early-morning > button {
    color: #c382d9;
  }

  .hour-selected {
    background: #00e676;
    box-shadow: 0px 4.50725px 13.5217px rgba(0, 230, 118, 0.5);
    border: 1px solid #00e676;
    color: #ffffff;
  }

  .hour-selected > button {
    color: #ffffff;
  }

  .hour-disabled {
    color: #a5abb0;
    border: 1px solid #a5abb0;
    background-color: transparent;
  }

  .hour-disabled > button {
    color: #a5abb0;
    background-color: transparent;
  }

  @media (max-width: 1280px) {
    .text-rateTable-dataTime {
      font-size: 12px;
    }

    .hour-slot > button {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: word-all;
    }
    .hour-slot > button {
      font-size: 12px;
    }
    .hour-slot > button > span {
      display: inline-block;
    }
  }
  @media (max-width: 480px) {
    .Row-header-dataTime {
      flex-direction: column;
      gap: 4px;
      p {
        font-size: 12px;
      }
    }

    .text-rateTable-dataTime {
      font-size: 12px;
    }

    .hour-slot > button {
      padding: 0px 2px;
      font-size: 11px;
      overflow: hidden;
    }
  }

  @media (max-width: 400px) {
    .Row-Content--dataTime {
      padding: 11px 2% 20px;
    }

    .Row-header-dataTime {
      flex-direction: column;
      gap: 4px;
      p {
        font-size: 12px;
      }
    }

    .hour-slot > button {
      padding: 0px 1px;
      font-size: 10px;
      overflow: hidden;
    }
  }
`;
export default ComponentTimeGrid;
