import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Input, Button, Select, Image, Popover, Form, DatePicker, TimePicker, ConfigProvider, notification, Card } from 'antd';
import es from 'antd/lib/locale/es_ES';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import GoogleMapReact from 'google-map-react';
import { usePlacesWidget } from 'react-google-autocomplete';
import InfiniteScroll from 'react-infinite-scroll-component';
import CardField from 'components/UI/CardField';
import { ReactComponent as IconSearch } from '../assets/ic-search.svg';
import { ReactComponent as LogoHorizontalMovil } from '../assets/LogoHorizontalMovil.svg';
import { ReactComponent as DataNotFound } from '../assets/enemy-ghost.svg';
import { CardContent, CardMap } from './SearchAllFields.styles';
import { useMediaQuery } from '../shared/utils';
import { getSports, getFields, getGeolocationViaIP } from '../services/http';
import UserMarker from "components/mapMarker/UserMarker";

moment.locale('es');
const { Option } = Select;
const dateFormat = 'DD MMM YYYY';

const ContentPopover = ({ infoSede }) => (
  <Link to={`searcher/sportscenter/${infoSede.idcentro}`}>
    <CardMap>
      <Col span={8} className='Col-ImagField'>
        <Image width={78} height={78} preview={false} src={infoSede.logosede} />
      </Col>
      <Col span={16} className='Col-infoCard'>
        <p className='Title-sede'>{infoSede.nombresede}</p>
        <p className='Inf-distance'>{`${infoSede.distance.toFixed(2)} km`}</p>
        <p className='Inf-direction'>{infoSede.direccion}</p>
      </Col>
    </CardMap>
  </Link>
);

const SedeLocation = ({ data }) => (
  <Popover content={() => <ContentPopover infoSede={data} />} overlayClassName='stylePopover'>
    <div className='styleMarker' />
  </Popover>
);

const SearchAllFields = (props) => {
  const today = moment().format('DD MMM YYYY');
  const allHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  const [checkHistory, setCheckHistory] = useState(false);
  const [showFixed, setShowFixed] = useState(false);
  const [selectedDate, setselectedDate] = useState(today);
  const [sports, setSports] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchedFields, setFetchedFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [sedes, setSedes] = useState([]);
  //const [errorAddress, setErrorAddress] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    total: 0,
  });
  const [hasMoreFields, setHasMoreFields] = useState(true);
  const [placeLocation, setPlaceLocation] = useState({});
  const [mapCenterLocation, setMapCenterLocation] = useState({});
  const [placesInputController, setPlacesInputController] = useState('');
  const [noFields, setNoFields] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 608px)');
  const history = useHistory();
  const location = useLocation();
  const mapRef = useRef(null);
  const mapsRef = useRef(null);
  const antInputRef = useRef(null);
  const [form] = Form.useForm();
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const country = place.address_components.filter((components) => components.types[0] === 'country')[0].short_name.toLowerCase();
      // console.log({ place, country });
      setPlaceLocation({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        country,
      });
      setMapCenterLocation((prev) => ({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      }));
      antInputRef.current.input.value = place?.formatted_address;
      setPlacesInputController(place?.formatted_address);
    },
    language: 'es-419',
    options: {
      types: ['address'],
      componentRestrictions: { country: ['co', 'ec'] },
      fields: ['formatted_address', 'geometry', 'address_components'],
    },
  });

  const onPlaceChange = (e) => {
    setPlacesInputController(e.target.value);
  };

  const updateSelectedDate = (selected) => {
    setselectedDate(selected.format('DD MMM YYYY'));
  };

  const disabledDate = (current) => {
    // Deshabilitar los días pasados al actual
    return current && current < moment(today, 'DD MMM YYYY');
  };

  const disabledHours = (current) => {
    // let blockedhours = [0, 1, 2, 3, 4, 5, 6];
    if (moment(today, 'DD MMM YYYY') >= moment(selectedDate, 'DD MMM YYYY')) {
      const actualHour = parseInt(moment().format('HH'), 10);
      return allHours.filter((hora) => hora <= actualHour);
    }
    // return blockedhours;
  };

  const defaultTime = () => {
    const datetime = moment(Date.now());
    if (datetime.get('minute') > 30) {
      return moment(datetime.set('hour', datetime.get('hour') + 1).set('minute', 0), 'HH:mm');
    } else {
      // return moment(datetime.set('minute', 30), 'HH:mm');
      return moment(datetime.set('hour', datetime.get('hour') + 1).set('minute', 0), 'HH:mm');
    }
  };

  const initialDateTime = {
    date: moment(selectedDate, dateFormat),
    time: defaultTime(),
  };

  const onFinishFailed = (errorInfo) => {
    //console.log('Failed:', errorInfo);
  };

  const backToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const prepareSedes = (fieldsArr) => {
    const sedesMap = Array.from(new Set(fieldsArr.map((arr) => arr.idsede))).map((idsede) => {
      return {
        idsede: idsede,
        idcentro: fieldsArr.find((arr) => arr.idsede === idsede).idcentro,
        logosede: fieldsArr.find((arr) => arr.idsede === idsede).logosede,
        nombresede: fieldsArr.find((arr) => arr.idsede === idsede).nombresede,
        distance: fieldsArr.find((arr) => arr.idsede === idsede).distance,
        direccion: fieldsArr.find((arr) => arr.idsede === idsede).direccion,
        coordenadas: {
          lat: fieldsArr.find((arr) => arr.idsede === idsede).latitud,
          lng: fieldsArr.find((arr) => arr.idsede === idsede).longitud,
        },
      };
    });
    const cleaned = sedesMap.filter((sede) => sede.idsede);
    setSedes(cleaned);
  };

  const geoLocationRequestViaIP = async () => {
    try {
      const request = await getGeolocationViaIP();
      if (request.result) {
        const { geodata } = request;
        setMapCenterLocation((prev) => ({
          latitude: geodata.latitude,
          longitude: geodata.longitude,
        }));
        const preparedTime = initialDateTime.date.set('hour', initialDateTime.time.get('hour')).set('minute', initialDateTime.time.get('minute'));
        const searchValues = {
          date: initialDateTime.date.format('YYYY-MM-DD'),
          time: preparedTime.set('second', 0).unix(),
          sport: 1,
          latitude: geodata.latitude,
          longitude: geodata.longitude,
          country: geodata.countryCode.toLowerCase(),
        };
        requestFields(searchValues.date, searchValues.time, searchValues.sport, searchValues.latitude, searchValues.longitude, searchValues.country);

        notification.success({
          message: 'Estamos utilizando tu ubicación aproximada',
          description: 'Utilizamos tu ubicación aproximada para poder brindarte mejores resultados de búsqueda',
          duration: 3,
        });
      }
    } catch (error) {
      notification.warning({
        message: 'No podemos acceder a tu ubicación',
        description: 'Ocurrió un error intentando ubicar tu ubicación de forma aproximada, si el problema persiste por favor contacta a soporte en info@icancha.com',
        duration: 3,
      });
    }
  };

  const requestSports = async () => {
    try {
      const response = await getSports();
      const { cod, data } = response;
      if (cod === 1000) {
        setSports(data);
        form.setFieldsValue({ 
           sport: data[0].iddeporte,
          });
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      //console.log('error requestSports: ', error);
      notification.warning({
        message: 'No podemos hacer contacto con el servidor iCancha',
        description: 'Ocurrió un error intentando obtener el listado de deportes del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
        duration: 3,
      });
    }
  };

  const requestFields = async (date, time, sport, latitude, longitude, country) => {
    try {
      setFetchingData(true);
      const response = await getFields(date, time, sport, latitude, longitude, country);
      const { cod, data } = response;
      if (cod === 1000) {
        setFetchedFields(data);
        const totalPages = Math.ceil(data.length / 10);
        const currentPage = 0;
        setPagination({
          currentPage,
          totalPages,
        });
        const getFirstPage = paginate(data, 10, currentPage);
        if (totalPages === 1) {
          setHasMoreFields(false);
        }
        setNoFields(false);
        setFields(getFirstPage);
        setFetchingData(false);
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      setFetchingData(false);
      //console.log('error requestFields: ', error);
      if (error.message === '1003') {
        notification.warning({
          message: 'No hay canchas disponibles',
          description: 'No tenemos canchas disponibles en la zona indicada, por favor intenta en otra ciudad',
          duration: 3,
        });
        setNoFields(true);
      } else {
        notification.warning({
          message: 'No podemos hacer contacto con el servidor iCancha',
          description: 'Ocurrió un error intentando obtener el listado de canchas del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
          duration: 3,
        });
        setNoFields(true);
      }
    }
  };

  const onFinish = (values) => {
    const preparedTime = values.date.set('hour', values.time.get('hour')).set('minute', values.time.get('minute'));
    const searchValues = {
      date: values.date.format('YYYY-MM-DD'),
      time: preparedTime.set('second', 0).unix(),
      sport: values.sport,
      latitude: 0,
      longitude: 0,
      country: placeLocation.country,
    };
    if (!antInputRef.current.input.value) {
      //setErrorAddress(true);
      searchValues.latitude = mapCenterLocation.latitude;
      searchValues.longitude = mapCenterLocation.longitude;
    } else {
      searchValues.latitude = placeLocation.latitude;
      searchValues.longitude = placeLocation.longitude;
    }
    setFetchedFields([]);
    setFields([]);
    setHasMoreFields(true);

    if (!isDesktop) {
      document.body.scrollTop = 400; // For Safari
      document.documentElement.scrollTop = 400;
    }
    requestFields(searchValues.date, searchValues.time, searchValues.sport, searchValues.latitude, searchValues.longitude, searchValues.country);
  };

  const paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, page_number * page_size + page_size);
  };

  const showMoreFields = () => {
    setTimeout(() => {
      const nextPage = pagination.currentPage + 1;
      const getAnotherPage = paginate(fetchedFields, 10, nextPage);
      if (getAnotherPage.length === 0) {
        setHasMoreFields(false);
      } else {
        setPagination((prev) => ({ ...prev, currentPage: nextPage }));
        setFields((prev) => [...prev].concat(getAnotherPage));
      }
    }, 500);
  };

  const handleApiLoaded = (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;
  };

  const getSelectedDateTime = () => {
    const formDate = form.getFieldValue('date');
    const formTime = form.getFieldValue('time');
    const date = formDate.format('YYYY-MM-DD');
    const time = formDate.set('hour', formTime.get('hour')).set('minute', formTime.get('minute')).set('second', 0).unix();
    return { date, time };
  };

  // History Effect check if user came from Home
 useEffect(() => {
  try {
    const { location: { state: historyState } } = history;
    const { sports, searchParams: historySearchParams } = historyState;

    setSports(sports);
    setCheckHistory(true);
    setMapCenterLocation({
      latitude: historySearchParams.latitude,
      longitude: historySearchParams.longitude
    });

    const selectedDateTime = moment(historySearchParams.time * 1000);
    const now = moment(); // Momento actual
    const timeExactOrHalf =
      now.minutes() > 30
        ? now.add(1, "hour").startOf("hour")
        : now.startOf("hour").add(30, "minutes"); 
    
    if (selectedDateTime.isBefore(now)) {
      console.warn("La fecha y hora seleccionadas ya pasaron. Ajustando a la actual.");
      form.setFieldsValue({
        sport: historySearchParams.sport,
        date: timeExactOrHalf, // Fecha actual
        time: timeExactOrHalf // Hora actual
      });

      const newState = { ...location.state, time: timeExactOrHalf.unix() };
      if (!location.state || location.state.time !== newState.time) {
        history.replace(location.pathname, newState);
      } 
    } else {
      form.setFieldsValue({
        sport: historySearchParams.sport,
        date: selectedDateTime || timeExactOrHalf,
        time: selectedDateTime || timeExactOrHalf
      });
    }

    requestFields(
      historySearchParams.date,
      historySearchParams.time,
      historySearchParams.sport,
      historySearchParams.latitude,
      historySearchParams.longitude,
      historySearchParams.country
    );

  } catch (error) {
    if (!checkHistory) {
      console.warn('No se obtuvieron state desde el home');
      geoLocationRequestViaIP();
      requestSports();
    }
  }
}, [history]);

  // Mobile only Effect
  useEffect(() => {
    const onScroll = () => {
      const newShowFixed = window.scrollY > 400;
      showFixed !== newShowFixed && setShowFixed(newShowFixed);
    };
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  });

  useEffect(() => {
    prepareSedes(fields);
  }, [fields]);

  useEffect(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    if (sedes.length > 0 && maps) {
      const bounds = new maps.LatLngBounds();
      sedes.forEach((sede) => bounds.extend(new maps.LatLng(sede.coordenadas.lat, sede.coordenadas.lng)));
      bounds.extend(new maps.LatLng(mapCenterLocation.latitude, mapCenterLocation.longitude));
      map.fitBounds(bounds);
    }
  }, [sedes]);

  useEffect(() => {
    if (props.hamburguercolor) {
      props.hamburguercolor('#ffff');
    }
  }, [props]);

  // Debugger Effect
  /*   useEffect(() => {
    setFetchedFields(aval);
    const totalPages = Math.ceil(aval.length / 10);
    const currentPage = 1;
    setPagination({
      currentPage,
      totalPages,
    });
    //console.log({ totalPages });
    const getFirstPage = paginate(aval, 10, 1);
    debugger;
    setFetchingData(true);
    setTimeout(() => {
      setFields(getFirstPage);
      setFetchingData(false); 
    }, 2000);
  }, []); */

  const timePickerHalf = () => {
    const now = moment(); // Momento actual
    const timeExactOrHalf =
      now.minutes() > 30
        ? now.add(1, "hour").startOf("hour")
        : now.startOf("hour").add(30, "minutes"); 
        return timeExactOrHalf
  }

  return (
    <ConfigProvider locale={es}>
      <CardContent fixed={showFixed ? showFixed : undefined} desktop={isDesktop ? `${isDesktop}` : ""}>
        <Col className='Col-Content--InfoFields' xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
          {
            <>
              {!isDesktop && (
                <>
                  <Row style={{ height: '80px', alignContent: 'center' }}>
                    <Col span={24} style={{ paddingLeft: '20px' }}>
                      <Link to=''>
                        <LogoHorizontalMovil style={{ width: '130px' }} />
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
              <Form form={form} name='innerSearch' onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Row gutter={[12, 16]} className='Row-Select--menu'>
                  <Col style={{ paddingRight: '5px', display: 'inline-grid', zIndex: '10' }}>
                    <label>Deporte</label>
                    <Form.Item name='sport' rules={[{ required: true }]}>
                      <Select notFoundContent='No hay datos' showArrow={false} placeholder='Elige un deporte'>
                        {sports.length > 0 &&
                          sports?.map((sport) => (
                            <Option key={sport.iddeporte} value={sport.iddeporte}>
                              {sport.nombre}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={{ paddingRight: '5px' }}>
                    <label>Tú Ubicación</label>
                    <Input
                      ref={(c) => {
                        antInputRef.current = c;
                        if (c) ref.current = c.input;
                      }}
                      value={placesInputController}
                      placeholder='Ingresa una dirección'
                      onChange={onPlaceChange}
                    />
                    {/*errorAddress && <div className='address-error'>Debes indicar una dirección</div>*/}
                  </Col>
                  <Col className='Col-data--picker'>
                    <label>Fecha</label>
                    <Form.Item name='date' rules={[{ required: true, message: '¡Por favor selecciona una fecha válida' }]} initialValue={initialDateTime.date}>
                      <DatePicker inputReadOnly placeholder='' defaultValue={moment()}  allowClear={false} format={dateFormat} disabledDate={disabledDate} onChange={updateSelectedDate} />
                    </Form.Item>
                  </Col>
                  <Col className='Col-data--picker'>
                    <label>Hora</label>
                    <Form.Item name='time' rules={[{ required: true, message: '¡Por favor selecciona una hora válida' }]} initialValue={timePickerHalf()}>
                      <TimePicker hideDisabledOptions defaultValue={timePickerHalf()} inputReadOnly showNow='false' placeholder='' allowClear={false} minuteStep={30} format='HH:mm' disabledHours={disabledHours} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      {isDesktop ? (
                        <Button type='primary' htmlType='submit' style={{ width: '45px', height: '45px', borderRadius: '10px' }}>
                          <IconSearch style={{ margin: '5px -5px' }} />
                        </Button>
                      ) : (
                        <Button type='primary' htmlType='submit' style={{ width: '100%', height: '45px', borderRadius: '10px' }}>
                          Buscar
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Row className='row--backtop'>
                <Button className='button--floating-search' type='primary' onClick={backToTop} style={{ width: '50px', height: '50px', borderRadius: '10px' }}>
                  <IconSearch style={{ margin: '5px -5px' }} />
                </Button>
              </Row>

              <Row className='Row-Content--sliderFields' id='fieldsContainer'>
                <Col span={22}>
                  {fetchingData && <CardField loading />}
                  {noFields && (
                    <div className='dataNotFound--container'>
                      <DataNotFound />{' '}
                      <p>
                        Hemos llegado a una zona desconocida. <br />
                        No tenemos canchas disponibles en la zona indicada, por favor intenta en otra ciudad
                      </p>
                    </div>
                  )}
                  {isDesktop && fields.length > 0 && (
                    <InfiniteScroll
                      scrollableTarget='fieldsContainer'
                      dataLength={fields.length}
                      next={showMoreFields}
                      hasMore={hasMoreFields}
                      endMessage={
                        <p style={{ textAlign: 'center', color: '#96989A', width: '100%', marginTop: '30px' }}>
                          <b>No hay más canchas que cumplan los criterios, por favor realiza otra búsqueda</b>
                        </p>
                      }
                      //endMessage={'No hay más canchas que cumplan los criterios, por favor realiza otra búsqueda'}
                      loader={<CardField loading />}>
                      <Col span={24}>
                        {fields.map((field, index) =>
                          field.banner ? (
                            <Card key={field.iddescuento + index} style={{ marginTop: '30px', border: 'unset' }} bodyStyle={{ padding: 'unset' }}>
                              {field.urldestino ? (
                                <Link to={{ pathname:field.urldestino, state: history?.location?.state}}>
                                  <img src={field.urlbanner} alt='promotion-banner' style={{ width: '100%', objectFit: 'cover', borderRadius: '8px' }} />
                                </Link>
                              ) : (
                                <img src={field.urlbanner} alt='promotion-banner' style={{ width: '100%', objectFit: 'cover', borderRadius: '8px' }} />
                              )}
                            </Card>
                          ) : (
                            <CardField key={field.idcancha} index={index} fieldData={field} timeValues={getSelectedDateTime}/>
                          )
                        )}
                      </Col>
                    </InfiniteScroll>
                  )}
                  {!isDesktop && fields.length > 0 && (
                    <InfiniteScroll
                      dataLength={fields.length}
                      next={showMoreFields}
                      hasMore={hasMoreFields}
                      endMessage={
                        <p style={{ textAlign: 'center', color: '#96989A', width: '100%', marginTop: '30px' }}>
                          <b>No hay más canchas que cumplan los criterios, por favor realiza otra búsqueda</b>
                        </p>
                      }
                      //endMessage={'No hay más canchas que cumplan los criterios, por favor realiza otra búsqueda'}
                      loader={<CardField loading />}>
                      <Col span={24}>
                        {fields.length > 0 &&
                          fields.map((field, index) =>
                            field.banner ? (
                              <Card key={field.iddescuento + index} style={{ marginTop: '10px', border: 'unset', borderRadius: '10px' }} bodyStyle={{ padding: 'unset' }}>
                                {field.urldestino ? (
                                  <Link to={{ pathname:field.urldestino, state: history?.location?.state}}>
                                    <img src={field.urlbannerm} alt='promotion-banner' style={{ width: '100%', objectFit: 'cover', borderRadius: '8px' }} />
                                  </Link>
                                ) : (
                                  <img src={field.urlbannerm} alt='promotion-banner' style={{ width: '100%', objectFit: 'cover', borderRadius: '8px' }} />
                                )}
                              </Card>
                            ) : (
                              <CardField key={field.idcancha} index={index} fieldData={field} timeValues={getSelectedDateTime}  />
                            )
                          )}
                      </Col>
                    </InfiniteScroll>
                  )}
                </Col>
              </Row>
            </>
          }
        </Col>
        <Col className='Col-Content--map' xs={0} sm={0} md={0} lg={10} xl={10} xxl={10}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries: ['places'] }}
            options={{
              fullscreenControl: false,
              clickableIcons: false, //desactiva funcion de click a los iconos
              disableDefaultUI: false,
              panControl: false,
              //zoomControl: false,
              mapId: '34ec851272486dad',
            }}
            center={{
              lat: mapCenterLocation.latitude,
              lng: mapCenterLocation.longitude,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            defaultZoom={14}>
            {mapCenterLocation.latitude && mapCenterLocation.longitude && <UserMarker lat={mapCenterLocation.latitude} lng={mapCenterLocation.longitude} />}
            {sedes.length > 0 && sedes.map((field, index) => <SedeLocation key={field.idsede} data={field} {...field.coordenadas} />)}
          </GoogleMapReact>
        </Col>
      </CardContent>
    </ConfigProvider>
  );
};

export default SearchAllFields;
