import React, { useState, useEffect, useMemo, Children } from "react";
import { Row, Col, Button, Skeleton } from "antd";
import { ReactComponent as IcSun } from "../../assets/ic-sun.svg";
import { ReactComponent as IcSunsent } from "../../assets/ic-sunset.svg";
import { ReactComponent as IcMoon } from "../../assets/ic-moon.svg";
import { ReactComponent as IcSunrise } from "../../assets/ic-sunrise.svg";
import ComponentTimeGrid from "./TimeGrid.style";
import {
  addDays,
  addHours,
  differenceInMinutes,
  format,
  getHours,
  getMinutes,
  getUnixTime,
  isBefore,
  isEqual,
  set,
  startOfDay,
} from "date-fns";
// isSameDay,

function parseTimeGridToDate(data = [], currentDate) {
  const currentDateSelect = currentDate ? new Date(currentDate) : new Date();
  const currentDateFormat = format(currentDateSelect, "yyyy-MM-dd");

  const parser = data.map((rateTimeGrid) => {
    const rate = {
      idplanillatarifa: rateTimeGrid?.idplanillatarifa,
      start: new Date(`${currentDateFormat}T${rateTimeGrid?.start_time}`),
      end: new Date(`${currentDateFormat}T${rateTimeGrid?.end_time}`),
      monto: parseFloat(rateTimeGrid?.monto),
      selected: rateTimeGrid?.selected,
      enabled: rateTimeGrid?.enabled,
      available: rateTimeGrid?.available,
    };
    return rate;
  });

  // console.log(parser);
  return parser;
  //.map((rate) => ({ ...rate, enabled: rate.enabled,})); //.filter(({ start }) => start >= today);
}

const TimeGrid = (props) => {
  const {
    preSelectData,
    fieldTimeGrid,
    manageSelection,
    currentDate,
    loading,
  } = props;

  const [ratesSelectedTime, setRatesSelectedTime] = useState([]);
  const rateDataGrid = useMemo(
    () => parseTimeGridToDate(fieldTimeGrid, currentDate),
    [fieldTimeGrid, currentDate]
  );

  const handleClick = (rateTable, index) => {
    // Busca si el elemento ya está en la lista seleccionada
    const existsIndex = ratesSelectedTime.findIndex((r) =>
      isEqual(r?.start, rateTable?.start)
    );
    // Obtiene los tiempos de inicio y fin en formato Unix
    const startUnix = getUnixTime(rateTable.start);
    const endUnix = getUnixTime(rateTable.end);
    // Calcula el total del monto de la selección
    const totalMonto =
      ratesSelectedTime.reduce((acc, item) => acc + item?.monto, 0) +
      (rateTable.monto || 0);

    // Si la selección está vacía, agrega el primer elemento y termina
    if (ratesSelectedTime.length === 0 && existsIndex === -1) {
      setRatesSelectedTime([rateTable]);
      manageSelection(startUnix, endUnix, rateTable.monto);
      return;
    }

    // Obtiene el último elemento seleccionado y su índice en la lista principal
    const lastSelected =
      ratesSelectedTime[ratesSelectedTime.length - 1] || null;
    const lastIndex = rateDataGrid.findIndex((r) =>
      isEqual(r?.start, lastSelected?.start)
    );

    // Verifica si el nuevo elemento pertenece a otro período o no es el siguiente en la lista
    const isDifferentPeriod =
      lastSelected && rateTable.key !== lastSelected?.key;
    const isNotNextInList = lastSelected ? false : lastIndex + 1 !== index;

    // Ordena los elementos seleccionados por su tiempo de inicio
    const ratesSortedTime = [...ratesSelectedTime].sort(
      (a, b) => a.start - b.start
    );

    // Obtiene el primer y último elemento seleccionado
    // const maxStart = ratesSortedTime[ratesSortedTime.length - 1];
    // const minStart = ratesSortedTime[0];

    // Calcula la diferencia en minutos con el primer, último y nuevo elemento
    // const totalDifferenceHoursMayor = maxStart
    //   ? differenceInMinutes(rateTable.start, maxStart?.start)
    //   : 0;
    // const totalDifferenceHoursMinor = minStart
    //   ? differenceInMinutes(rateTable.start, minStart?.start)
    //   : 0;
    // const totalDifferenceHours = lastSelected
    //   ? differenceInMinutes(rateTable.start, lastSelected?.start)
    //   : 0;

    // Determina si se debe mantener el elemento o eliminarlo de la selección
    let rateKeep = null;
    if (existsIndex !== -1) {
      const removeRate = ratesSortedTime.filter(
        (r) => !isEqual(r?.start, rateTable?.start)
      );

      removeRate.forEach((r, ix) => {
        if (ix === 0) {
          rateKeep = r;
          return;
        }
        if (
          [60, 90].includes(
            Math.abs(differenceInMinutes(r.start, rateKeep?.start))
          )
        ) {
          rateKeep = r;
          return;
        }
        rateKeep = undefined;
        return;
      });
    }

    // Si es un período diferente o no es el siguiente en la lista, reinicia la selección
    if (isDifferentPeriod || isNotNextInList) {
      setRatesSelectedTime(existsIndex === -1 ? [rateTable] : []);
      manageSelection(startUnix, endUnix, rateTable.monto);
      return;
    }

    // Si `rateKeep` es undefined, reinicia la selección
    if (rateKeep === undefined) {
      setRatesSelectedTime([rateTable]);
      manageSelection(startUnix, endUnix, rateTable.monto);
      return;
    }

    // Si la diferencia es 60 o 90 minutos, agrega o quita el elemento de la selección
    // console.log(ratesSortedTime, ratesSelectedTime)
    // console.log(
    //   format(ratesSortedTime[ratesSortedTime.length - 1].end, "H:mm"),
    //   format(rateTable.start, "H:mm"),
    //   isEqual(ratesSortedTime[ratesSortedTime.length - 1].end, rateTable.start),
    //   "---------------------------------------------------------------------\n",
    //   format(rateTable.end, "H:mm"),
    //   format(ratesSortedTime[ratesSortedTime.length - 1].start, "H:mm"),
    //   isEqual(rateTable.end, ratesSortedTime[ratesSortedTime.length - 1].start),
    //   "---------------------------------------------------------------------\n",
    //   format(rateTable.end, "H:mm"),
    //   format(ratesSortedTime[0].start, "H:mm"),
    //   isEqual(rateTable.end, ratesSortedTime[0].start),
    // );
    if (existsIndex === -1) {
      if (
        // [60, 90].includes(Math.abs(totalDifferenceHoursMayor)) ||
        // [60, 90].includes(Math.abs(totalDifferenceHours)) ||
        // [60, 90].includes(Math.abs(totalDifferenceHoursMinor)) ||
        isEqual(
          rateTable.end,
          ratesSortedTime[ratesSortedTime.length - 1].start
        ) ||
        isEqual(
          ratesSortedTime[ratesSortedTime.length - 1].end,
          rateTable.start
        ) ||
        isEqual(rateTable.end, ratesSortedTime[0].start)
      ) {
        const ratesSortedWithRate = [...ratesSortedTime, rateTable].sort(
          (a, b) => a.start - b.start
        );
        setRatesSelectedTime(ratesSortedWithRate);
        manageSelection(
          getUnixTime(ratesSortedWithRate[0].start),
          getUnixTime(ratesSortedWithRate[ratesSortedWithRate.length - 1].end),
          totalMonto
        );
      } else {
        setRatesSelectedTime([rateTable]);
        manageSelection(startUnix, endUnix, rateTable.monto);
      }
    } else {
      const ratesFilter = ratesSortedTime.filter(
        (r) => !isEqual(r?.start, rateTable?.start)
      );
      if (ratesFilter.length <= 0) {
        manageSelection(null, null, null);
        setRatesSelectedTime([]);
        return;
      }
      const currentAmount = ratesFilter.reduce(
        (acc, item) => acc + item?.monto,
        0
      );
      setRatesSelectedTime(ratesFilter);
      manageSelection(
        getUnixTime(ratesFilter[0]?.start),
        getUnixTime(ratesFilter[ratesFilter.length - 1]?.end),
        currentAmount
      );
    }

    // Si solo queda un elemento y se intenta quitar, vacía la selección
    if (ratesSelectedTime.length === 1 && existsIndex === 0) {
      setRatesSelectedTime([]);
      manageSelection(null, null, null);
    }
  };

  // Inicio del día actual
  const headerReservations = useMemo(() => {
    const now = startOfDay(new Date(currentDate));
    return [
      {
        Icon: () => <IcSunrise />,
        text: "Madrugada",
        key: "early_morning",
        start: now,
        end: addHours(now, 6),
        className: "hour-early-morning",
      },
      {
        Icon: () => <IcSun />,
        text: "Día",
        key: "morning",
        start: addHours(now, 6),
        end: addHours(now, 12),
        className: "hour-morning",
      },
      {
        Icon: () => <IcSunsent />,
        text: "Tarde",
        key: "afternoon",
        start: addHours(now, 12),
        end: addHours(now, 18),
        className: "hour-afternoon",
      },
      {
        Icon: () => <IcMoon />,
        text: "Noche",
        key: "night",
        start: addHours(now, 18),
        end: set(addHours(now, 23), { minutes: 59, seconds: 59 }),
        className: "hour-night",
      },
    ];
  }, [currentDate]);

  const toMinutes = (date) => getHours(date) * 60 + getMinutes(date);

  const columnsEnabled = headerReservations.map((slots, ix) => {
    return rateDataGrid.some(
      (rateGrid) =>
        toMinutes(rateGrid?.start) >= toMinutes(slots?.start) &&
        toMinutes(rateGrid?.start) < toMinutes(slots?.end) &&
        rateGrid.enabled &&
        rateGrid.available &&
        ix === 0 &&
        rateGrid.start >= new Date()
    );
  });

  // const avaibleHoursFilters = headerReservations.map((slots, ix) => {
  //   return rateDataGrid.filter(
  //     (rateGrid) =>
  //       toMinutes(rateGrid?.start) >= toMinutes(slots?.start) &&
  //       toMinutes(rateGrid?.start) < toMinutes(slots?.end) &&
  //       rateGrid.enabled
  //   );
  // });

  useEffect(() => {
    const currentTime = !currentDate
      ? new Date()
      : new Date(
          `${format(new Date(currentDate), "yyyy-MM-dd")}T${format(
            new Date(),
            "HH:mm:ss"
          )}`
        );
    if (preSelectData && Object.keys(preSelectData).length > 0 && !loading) {
      const startTime = new Date(
        `${format(currentTime, "yyyy-MM-dd")}T${preSelectData.hora}`
      );

      if (startTime < currentTime) return;

      const rateSlot = rateDataGrid.find((slots) =>
        isEqual(slots?.start, startTime)
      );

      if (!rateSlot) return;
      const rateSlotFoundTime = headerReservations.find(
        (slots) =>
          toMinutes(rateSlot?.start) >= toMinutes(slots?.start) &&
          toMinutes(rateSlot?.start) < toMinutes(slots?.end)
      );
      rateSlot.key = rateSlotFoundTime?.key;
      // fix porque se selecciona automatico a la hora actual
      if (rateSlot && startTime > new Date()) {
        setRatesSelectedTime([rateSlot]);
        manageSelection(
          getUnixTime(rateSlot?.start),
          getUnixTime(rateSlot?.end),
          rateSlot.monto
        );
      }
    }
  }, [
    preSelectData,
    rateDataGrid,
    manageSelection,
    headerReservations,
    currentDate,
    loading,
  ]);

  useEffect(() => {
    if (loading) {
      setRatesSelectedTime((prev) => (prev.length > 0 ? [] : prev));
      manageSelection(null, null, 0);
    }
  }, [loading, manageSelection]);

  return (
    <ComponentTimeGrid>
      <Row className="Row-Content--dataTime">
        <Col span={24}>
          {loading ? (
            <>
              <Row>
                <div style={{ paddingTop: "8px", width: "100%" }}>
                  <Skeleton.Button
                    active="true"
                    style={{ height: "264px", width: "100%" }}
                  />
                </div>
              </Row>
            </>
          ) : (
            <Row className="Row-title-dataTime">
              {headerReservations.map(
                ({ Icon, key, text, end, start, className }, index) => {
                  const showColunm = !columnsEnabled[index];
                  const showColunmEarly = showColunm && index === 0;
                  if (showColunmEarly) return null;
                  const avaibleHours = rateDataGrid.filter(
                    (rateGrid) =>
                      toMinutes(rateGrid?.start) >= toMinutes(start) &&
                      toMinutes(rateGrid?.start) < toMinutes(end) &&
                      rateGrid.enabled
                  );

                  const rateHeigths = [];
                  let px = 38;
                  for (let minutos = 60; minutos <= 360; minutos += 30) {
                    rateHeigths.push({ m: minutos, px });
                    px += rateHeigths.length > 4 ? 23 : 21;
                  }
                  return (
                    <Col
                      key={`${key}_${text}_${index}`}
                      span={!columnsEnabled[0] ? 7 : 6}
                      className="Col-content-dataTime"
                    >
                      <div className="Row-header-dataTime">
                        <Icon />
                        <p>{text}</p>
                      </div>

                      {avaibleHours.length === 0 ? (
                        <div className="Col-rateTable-dataTime Col-rateTable-dataTime-availability">
                          <p className="text-rateTable-dataTime">
                            Sin disponibilidad
                          </p>
                        </div>
                      ) : (
                        <div className="Col-rateTable-dataTime">
                          {avaibleHours?.map((rate, ix) => {
                            const today = new Date();
                            const endDate = isBefore(rate.end, rate.start)
                              ? addDays(rate.end, 1)
                              : rate.end;

                            const duration = differenceInMinutes(
                              endDate,
                              rate.start
                            );
                            const selectedDate = ratesSelectedTime.some(
                              (rateSelect) =>
                                isEqual(rateSelect.start, rate.start)
                            );

                            const formattedStart = format(rate.start, "h:mm");
                            const formattedEnd = format(rate.end, "h:mm");
                            const heightHour = rateHeigths.find(
                              (rateh) => rateh.m === duration
                            ).px;

                            return (
                              <Row
                                key={ix}
                                className="Row-grid"
                                style={{
                                  flexGrow: 1,
                                  maxHeight: `${heightHour}px`,
                                }}
                              >
                                <Col
                                  span={24}
                                  className={`hour-slot ${className} ${
                                    duration === 90 ? "hour-slot-big" : ""
                                  } ${selectedDate ? "hour-selected" : ""} ${
                                    rate.enabled &&
                                    rate.available &&
                                    today < rate.start
                                      ? "hour-available"
                                      : "hour-disabled"
                                  }`}
                                >
                                  <Button
                                    block
                                    type="text"
                                    disabled={
                                      !(rate.enabled && rate.available) ||
                                      !(today < rate.start)
                                    }
                                    onClick={() =>
                                      handleClick({ ...rate, key }, ix)
                                    }
                                  >
                                    {formattedStart}
                                    {" - "}
                                    {formattedEnd}
                                  </Button>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      )}
                    </Col>
                  );
                }
              )}
            </Row>
          )}
        </Col>
      </Row>
    </ComponentTimeGrid>
  );
};

export default TimeGrid;
