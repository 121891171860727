import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
const API_URL = process.env.REACT_APP_URL + '/icapps'
const headers = { 'Content-Type': 'application/json' };

export const getIdentityCookie = () => {
  try {
    const cdata = Cookies.get('icappid');
    if (cdata) {
      const values = JSON.parse(cdata);
      return values.init;
    } else {
      return false;
    }
  } catch (error) {
  }
};

export const logOut = () => {
  Cookies.remove('icappid');
  window.location.reload();
};

export const getToken = () => {
  try {
    const cdata = Cookies.get('icappid');
    if (cdata) {
      const values = JSON.parse(cdata);
      return values.it;
    } else {
      return false;
    }
  } catch (error) {
  }
};

const createInitials = (decodedJWT) => {
  try {
    //
    if (decodedJWT.given_name && decodedJWT.family_name) {
      return `${decodedJWT.given_name.charAt(0).toUpperCase()}${decodedJWT.family_name.charAt(0).toUpperCase()}`;
    } else {
      return 'NA';
    }
  } catch (error) {
    console.log({ error });
    return 'NA';
  }
};

export const createIdentityCookie = (accessToken, idToken, init) => {
  try {
    const decodedJWT = jwt_decode(idToken);
    const customOrigin = process.env.REACT_APP_CENTER_KEY;
    let initials;
    if (init) {
      initials = init;
    } else {
      initials = createInitials(decodedJWT);
    }
    // debugger;
    const cdata = {
      init: initials,
      at: accessToken,
      it: idToken,
    };

    let expirationDate;
    if (customOrigin) {
      expirationDate = 1 / 48;
      Cookies.set('icappid', JSON.stringify(cdata), { expires: expirationDate });
      return initials;
    } else {
      expirationDate = new Date(decodedJWT.exp * 1000);
      Cookies.set('icappid', JSON.stringify(cdata), { expires: expirationDate });
      return initials;
    }
  } catch (error) {
    console.log('Error createIdentityCookie', error);
  }
};

export const startPasswordless = async (cellphone) => {
  try {
    let destination = `${API_URL}/auth/passwordless/start`;
    const connection = await axios({
      method: 'POST',
      url: destination,
      headers: headers,
      data: { cellphone },
    });
    const {
      data: { cod },
    } = connection;
    switch (cod) {
      case 1000:
        return true;
      default:
        return false;
    }
  } catch (error) {
  }
};

export const verifyOTPcode = async (code, cellphone) => {
  try {
    let destination = `${API_URL}/auth/passwordless/verify`;
    const customOrigin = process.env.REACT_APP_CENTER_KEY;

    let body;

    if (customOrigin) {
      body = { cellphone, code, origin: customOrigin };
    } else {
      body = { cellphone, code };
    }

    const connection = await axios({
      method: 'POST',
      url: destination,
      headers: headers,
      data: body,
    });
    const {
      data: { cod, data },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ verifyOTPcode: error });
  }
};

export const createUser = async (idToken, nombre, apellido, tipoDocumento, numeroDocumento, correo) => {
  try {
    let destination = `${API_URL}/usuarios`;
    const customOrigin = process.env.REACT_APP_CENTER_KEY;

    let bodyData;

    if (customOrigin) {
      if (correo) {
        bodyData = {
          origen: customOrigin,
          nombre,
          apellido,
          tipoDocumento,
          numeroDocumento,
          correo,
        };
      } else {
        bodyData = {
          origen: customOrigin,
          nombre,
          apellido,
          tipoDocumento,
          numeroDocumento,
        };
      }
    } else {
      if (correo) {
        bodyData = {
          nombre,
          apellido,
          tipoDocumento,
          numeroDocumento,
          correo,
        };
      } else {
        bodyData = {
          nombre,
          apellido,
          tipoDocumento,
          numeroDocumento,
        };
      }
    }

    const connection = await axios({
      method: 'POST',
      url: destination,
      headers: { ...headers, Authorization: `Bearer ${idToken}` },
      data: bodyData,
    });
    const {
      data: { cod, data },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ createUser: error });
  }
};

export const getUserData = async () => {
  try {
    let destination = `${API_URL}/usuarios`;
    const token = getToken();

    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: { ...headers, Authorization: `Bearer ${token}` },
    });
    const {
      data: { cod, data },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getUserData: error });
  }
};

export const getUserRsvp = async () => {
  try {
    let destination = `${API_URL}/usuarios/history/rsvp`;
    const token = getToken();

    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: { ...headers, Authorization: `Bearer ${token}` },
    });
    const {
      data: { cod, data },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    //console.log({ getUserRsvp: error });
  }
};

export const canUseCoupon = async (restrictions, idcentro, idcancha, idsede, loggedIn) => {
  try {
    if (restrictions && restrictions.length > 0) {
      const hasRestrictionsByUser = restrictions.some((restriction) => restriction.idusuario);
      const hasRestrictionsByField = restrictions.some((restriction) => restriction.idcancha);
      const hasRestrictionsByCenter = restrictions.some((restriction) => restriction.idcentro);
      const hasRestrictionsBySede = restrictions.some((restriction) => restriction.idsede);

      if (loggedIn) {
        if (hasRestrictionsByUser) {
          const userInfo = await getUserData();
          const { cod, data: user } = userInfo;
          if (cod !== 1000) return false;

          const userInRestrictions = restrictions.some((restriction) => restriction.idusuario === user.idusuario);
          if (!userInRestrictions) {
            return false;
          }
        }
      }

      if (hasRestrictionsByField) {
        const fieldInRestrictions = restrictions.some((restriction) => restriction.idcancha === idcancha);
        if (!fieldInRestrictions) {
          return false;
        }
      }

      if (hasRestrictionsByCenter) {
        const centerInRestrictions = restrictions.some((restriction) => restriction.idcentro === idcentro);
        if (!centerInRestrictions) {
          return false;
        }
      }

      if (hasRestrictionsBySede) {
        const sedeInRestrictions = restrictions.some((restriction) => restriction.idsede === idsede);
        if (!sedeInRestrictions) {
          return false;
        }
      }
    }
    return true;
  } catch (error) {
    console.log({ canUseCoupon: error });
  }
};
